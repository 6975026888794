import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { CostManagerMilestoneDto, MilestoneNotificationDto } from 'app/api/models';
import { CostManagerService } from 'app/api/services';
import { Subject } from 'rxjs';
import { MilestoneNotificationDialogComponent } from './milestone-notification-dialog/milestone-notification-dialog.component';
import { JwtAuthService } from 'app/core/services/cookie-jwt-auth/jwt-auth.service';
import { DOCUMENT } from '@angular/common';
import { User } from 'app/core/models/user.model';

@Component({
    selector       : 'app-milestone-notifications',
    templateUrl    : './milestone-notifications.component.html',
})
export class MilestoneNotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    pendingMilestones: MilestoneNotificationDto[] = [];

    constructor(
        @Inject(DOCUMENT) private document: any,
        private costManagerService: CostManagerService,
        private jwtAuth: JwtAuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private dialog: MatDialog,
    )
    {}

    ngOnInit(): void
    {
        this.loadMilestoneNotifications();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    loadMilestoneNotifications() {
        var user: User = this.jwtAuth.getUser();
        this.costManagerService.apiCostManagerGetOverdueMilestonesGet$Json$Response({authorUserId: +user.id}).subscribe(res => {
            if(!res?.body?.data) return;

            this.pendingMilestones = res.body.data;
            // console.log("milestones", this.pendingMilestones);
        });
    }

    openMilestone(milestone: MilestoneNotificationDto) {
        var dialog = this.dialog.open(MilestoneNotificationDialogComponent, {
            data: {
                milestone: milestone
            }
          });
        
          dialog.afterClosed().subscribe((dialogRes) => {
                if (!dialogRes) return;

                if(!dialogRes.submit) { //date change
                    this.costManagerService.apiCostManagerUpdateMilestonePost$Json$Response({body: dialogRes.milestone}).subscribe(res => {
                        if(!res?.body?.data) return;

                        this.loadMilestoneNotifications();
                    });
                }
                else
                { //submit to accounts
                    var user = this.jwtAuth.getUser();
                    var name = `${user?.firstName} ${user?.lastName}`;

                    var parsedUrl = new URL(this.document.location.href);
                    var baseUrl = parsedUrl.origin;
                    
                    dialogRes.milestone.milestoneOwnerId = +this.jwtAuth.getUser().id;

                    this.costManagerService.apiCostManagerSendMilestoneToAccountsPost$Json$Response({body: dialogRes.milestone, senderName: name, senderEmail: user.email, uri: baseUrl}).subscribe(res => {
                        if(!res?.body?.data) return;

                        this.loadMilestoneNotifications();
                    });
                }
          });
    }

    openPanel(): void
    {
        this.loadMilestoneNotifications();
        
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    closePanel(): void
    {
        this._overlayRef.detach();
    }

    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX : 'start',
                        originY : 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX : 'end',
                        originY : 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX : 'end',
                        originY : 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() =>
        {
            this._overlayRef.detach();
        });
    }
}
