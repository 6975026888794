import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { of, BehaviorSubject, throwError, Observable } from "rxjs";
import { AccountService } from "app/api/services";
import {
  AuthenticationRequest,
  AuthenticationResponse,
  RefreshTokenRequest,
} from "app/api/models";
import { CookieStoreService } from "./cookie-store.service";
import { User } from "./user.model";
import { LocalStoreService } from "./local-store.service";
import { HttpClient } from "@angular/common/http";
import { TimeUtil } from "app/core/utils/time-utils";

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  public refreshTokenValue: string;
  public refreshTokenExpiration: Date;
  private refreshTokenTimeout;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  REFRESH_TOKEN = "REFRESH_TOKEN_HC";
  APP_USER = "USER";

  constructor(
    private ls: LocalStoreService,
    private cs: CookieStoreService,
    private _httpClient: HttpClient, //todo temporary measure
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(
      (params) => (this.return = params["return"] || "/dashboard")
    );
  }

  public signin(request: AuthenticationRequest) {
    this.signingIn = true;

    return this.accountService
      .apiAccountAuthenticatePost$Json$Response({ body: request })
      .pipe(
        map((res: any) => {
          if (res?.body?.data) {
            this.setUserAndToken(res.body.data, !!res);
            this.signingIn = false;
            return res;
          }

          return null;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  isLoggedIn(): boolean {
    if (this.token) {
      var expired = this.tokenExpired(this.token);
      return !expired;
    } else {
      return false;
    }
  }

  isLoggedInObservable(): Observable<boolean> {
    if(this.isLoggedIn()) return of(true);

    if (this.checkForRefreshToken()) {
      return this.refreshToken()
        .pipe(
          map(res => {
            return !!res;
          }));
    }
    else {
      return of(false);
    }
  }

  isAdmin() {
    var user = this.getUser();
    return user && user.roles.includes("Admin");
  }

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  checkForRefreshToken() {
    var user = this.getUser();
    var refreshToken = this.getRefreshToken();
    console.log(new Date().toTimeString().split(' ')[0], "user and cookie get\n", user, '\n\n', refreshToken?.slice(-12));
    if (!user || !refreshToken) return false;

    return true;
  }

  getRefreshToken() {
    console.log(new Date().toTimeString().split(' ')[0], "cookie got\n\n", this.cs.getCookie(this.REFRESH_TOKEN)?.slice(-12));
    return this.cs.getCookie(this.REFRESH_TOKEN);
  }

  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  refreshToken() {
    // console.log("trying to refresh token");
    var user = this.getUser();
    var refreshToken = this.getRefreshToken();
    if (!user || !refreshToken) return of(null);

    // console.log("sending refresh request");

    var request: RefreshTokenRequest = {
      email: user.email,
      token: refreshToken,
      rememberMe: user.rememberMe ?? true,
    };
    console.log(new Date().toTimeString().split(' ')[0], "sending token refresh request\n\n", request?.token?.slice(-12));

    return this.accountService
      .apiAccountRefreshtokenPost$Json$Response({ body: request })
      .pipe(
        map((res) => {
          // console.log(new Date().toTimeString().split(' ')[0], "refresh response", res);
          if (res?.body?.data) {
            this.setUserAndToken(res.body.data, !!res);
            console.log(new Date().toTimeString().split(' ')[0], "set refresh token successful ", !!res, '\n\n', res.body.data.refreshToken?.slice(-12));
            return res;
          } else {
            this.setUserAndToken(null, false);
            console.log("set refresh token failed", res);
            return null;
          }
        })
      );
  }

  public signout() {
    var user = this.getUser();
    if (user?.email) {
      this.accountService
        .apiAccountLogoutGet$Json$Response({ userEmail: user.email })
        .subscribe((resp) => {
          this.removeAccountInformation();
        });
    } else this.removeAccountInformation();
  }

  private removeAccountInformation() {
    this.stopRefreshTokenTimer();
    this.setUserAndToken(null, false);
    this.router.navigate([""]);
  }

  public signoutTokenExpiry() {
    var user = this.getUser();
    if (user?.email) {
      this.accountService
        .apiAccountLogoutGet$Json$Response({ userEmail: user.email })
        .subscribe((resp) => {
          this.removeAccountInformationTokenExpiry();
        });
    } else this.removeAccountInformationTokenExpiry();
  }

  private removeAccountInformationTokenExpiry() {
    this.stopRefreshTokenTimer();
    this.setUserAndToken(null, false);
    this.router.navigate([""], {
      queryParams: {
        reason: "tokenExpiry",
      },
    });
  }

  setUserAndToken(data: AuthenticationResponse, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = data?.jwToken;
    this.refreshTokenValue = data?.refreshToken;
    if (data?.refreshTokenExpiration) {
      this.refreshTokenExpiration = new Date(data?.refreshTokenExpiration);
    } else {
      // var soon = TimeUtil.addHours(new Date(), 0.25);
      this.refreshTokenExpiration = TimeUtil.addHours(new Date(), 12.25);
    }
    this.user = data?.user;
    this.user$.next(this.user);

    if (this.token) {
      this.ls.setItem(this.APP_USER, this.user);
      this.cs.setCookie(
        this.REFRESH_TOKEN,
        this.refreshTokenValue,
        this.refreshTokenExpiration
      );
      console.log(new Date().toTimeString().split(' ')[0], "cookie set\n\n", this.refreshTokenValue?.slice(-12));
      this.startRefreshTokenTimer();
    } else {
      console.log(new Date().toTimeString().split(' ')[0], "clearing ls");
      this.ls.clear();
      this.stopRefreshTokenTimer();
    }
  }

  private startRefreshTokenTimer() {
    console.log(new Date().toTimeString().split(' ')[0], "starting refresh timer");
    //Refresh the token after 15 minutes of the page still being open.
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), 60000 * 60 * 12); //12 hours -- 30000 = 0.5 mins currently
  }

  private stopRefreshTokenTimer() {
    console.log("stopping refresh timer if exists");
    if (!this.refreshTokenTimeout) this.return;
    console.log("stopping refresh timer");

    clearTimeout(this.refreshTokenTimeout);
    this.refreshTokenTimeout = null;
  }

  
  forgotPassword(email: string): Observable<any>
  {
    var uri = window.location.origin + '/reset-password';
    return this.accountService.apiAccountForgotPasswordPost$Json({ body: { email, uri } });
  }

  resetPassword(email: string, password: string, confirmPassword: string, token: string): Observable<any>
  {
    return this.accountService.apiAccountResetPasswordPost$Json({ body: { email, password, confirmPassword, token } });
    //return this._httpClient.post('api/auth/reset-password', password);
  }
  
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
  {
    return this._httpClient.post('api/auth/sign-up', user);
  }
  
  unlockSession(credentials: { email: string; password: string }): Observable<any>
  {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }
}
